<!-- TODO: figure out if the <nav> should go inside of a <header> element. -->
<nav class="docs-navbar-header" aria-label="Top Toolbar"
     [class.is-next-version]="isNextVersion">
  <div class="skip-link-wrapper" [class.cdk-visually-hidden]="skipLinkHidden" *ngIf="skipLinkHref">
    <a mat-raised-button [href]="skipLinkHref" (focus)="skipLinkHidden = false" (blur)="skipLinkHidden = true" color="accent">
      Skip to main content
    </a>
  </div>
  <a mat-button class="docs-button" routerLink="/" aria-label="Angular Material">
    <img class="docs-angular-logo"
         src="../../../assets/img/homepage/angular-white-transparent.svg"
         alt="angular">
    <span>Material</span>
  </a>
  <a mat-button class="docs-navbar-hide-small docs-button"
     *ngFor="let key of sectionKeys"
     [routerLink]="key"
     routerLinkActive="docs-navbar-header-item-selected">{{sections[key].name}}</a>
  <a mat-button class="docs-navbar-hide-small docs-button" routerLink="guides" routerLinkActive="docs-navbar-header-item-selected">Guides</a>
  <div class="flex-spacer"></div>
  <version-picker></version-picker>
  <theme-picker></theme-picker>
  <a mat-button class="docs-button docs-navbar-hide-small" href="https://github.com/angular/components"
     aria-label="GitHub Repository">
    <img class="docs-angular-logo"
         src="../../../assets/img/homepage/github-circle-white-transparent.svg"
         alt="angular">
    GitHub
  </a>
  <a mat-icon-button class="docs-button docs-navbar-show-small"
     href="https://github.com/angular/components" aria-label="GitHub Repository">
    <img class="docs-angular-logo"
         src="../../../assets/img/homepage/github-circle-white-transparent.svg"
         alt="angular">
  </a>
</nav>
<nav class="docs-navbar docs-navbar-show-small" aria-label="Section Nav Bar">
  <a mat-button class="docs-navbar-link"
    *ngFor="let key of sectionKeys"
    [routerLink]="key">{{sections[key].name}}</a>
  <a mat-button class="docs-navbar-link" routerLink="guides">Guides</a>
</nav>
